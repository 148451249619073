import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './advantage.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTruckLoading,
    faHandshake,
    faSeedling,
    faAward,
    faHotel,
    faThumbsUp} from '@fortawesome/free-solid-svg-icons';




const Advantage = (props) => {
    const colorIcon = "#46644e"
    const sizeIcon = 2;
    return (
        <Container fluid className="pt-3 mb-5">
            <Row className="mt-5 ">
              <Col xs="6" md="2" className="text-uppercase text-center">
                <FontAwesomeIcon
                    icon={faHandshake}
                    color={colorIcon}
                    size="lg"
                    style={{fontSize: `${sizeIcon}em`}}
                    />
                    <p className="mt-3">Без бюрократии
                        </p>
              </Col>
              <Col xs="6" md="2" className="text-uppercase text-center">
                <FontAwesomeIcon
                    icon={faAward}
                    color={colorIcon}
                    size="lg"
                    style={{fontSize: `${sizeIcon}em`}}
                    />
                    <p className="mt-3">Элитные животные
                        </p>
              </Col>
              <Col xs="6" md="2" className="text-uppercase text-center">
                <FontAwesomeIcon
                    icon={faHotel}
                    color={colorIcon}
                    size="lg"
                    style={{fontSize: `${sizeIcon}em`}}
                    />
                    <p className="mt-3">Собственный карантин
                        </p>
              </Col>
              <Col xs="6" md="2" className="text-uppercase text-center">
                <FontAwesomeIcon
                    icon={faTruckLoading}
                    color={colorIcon}
                    size="lg"
                    style={{fontSize: `${sizeIcon}em`}}
                    />
                    <p className="mt-3">Доставка в Россию
                        </p>
              </Col>
              <Col xs="6" md="2" className="text-uppercase text-center">
                <FontAwesomeIcon
                    icon={faSeedling}
                    color={colorIcon}
                    size="lg"
                    style={{fontSize: `${sizeIcon}em`}}
                    />
                    <p className="mt-3">Чистая ферма
                        </p>
              </Col>
              <Col xs="6" md="2" className="text-uppercase text-center">
                <FontAwesomeIcon
                    icon={faThumbsUp}
                    color={colorIcon}
                    size="lg"
                    style={{fontSize: `${sizeIcon}em`}}
                    />
                    <p className="mt-3">
                        Немецкие традиции
                    </p>
              </Col>
            </Row>
        </Container>        
    );
}
export default Advantage;