import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './gellery-instagram.css';

export default class GelleryInstagram extends React.Component {
  
  render() {
    let instaStyle = "gel-insta-descttop"
  
  return (
    <Container>
        <h2 className="h3 text-center mt-5 mb-3">Живая галерея в Инстаграм</h2>
        <Row>
          <Col md="12">
            <iframe name="Instagram" title="Instagram" src="https://averin.pro/widget.php?l=groot___wood&style=1&width=100%&gallery=1&s=80&icc=6&icr=3&t=0&tt=@groot___wood&h=1&ttcolor=FFFFFF&th=01717d&bw=ffffff&bscolor=ffffff&bs=c5ad70&ts=Подписаться&ch=utf8" allowtransparency="true" frameBorder="0" scrolling="no" className={instaStyle} ></iframe>
          </Col>
        </Row>
      </Container>
  );
};
}

