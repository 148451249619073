import React from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './text-toggle.css'
import  {faAngleDown} from '@fortawesome/free-solid-svg-icons';

export default class TextToggle extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fadeIn: false
    }
    this.onToggle = this.onToggle.bind(this);
  }
  onToggle(e) {
    e.preventDefault();
    this.setState({fadeIn: !this.state.fadeIn})
  }

render() {
  const {fadeIn} = this.state;
  return(
    <>
    <div className="toggle-section">
      <button onClick={this.onToggle} className={fadeIn?"d-none":"toggle-more-text"}>
      ...<FontAwesomeIcon className="icon-down mr-2"
                    icon={faAngleDown}
                    color="#01717d"
                    size="lg"
                />
      </button>
    </div>
    <div className={fadeIn?"d-block":"d-none"}>
          {this.props.children}
    </div>
    <div className="toggle-section">
        <button onClick={this.onToggle} className={fadeIn?"toggle-more-text":"d-none"}>
          <FontAwesomeIcon className="icon-up mr-2"
                    icon={faAngleDown}
                    color="#01717d"
                    size="lg"
                />
      </button>
    </div>
  </>
  )
}
}
TextToggle.propTypes = {
  children: PropTypes.node.isRequired,
}

