import React from 'react';
import TextToggle from '../components/text-toggle/text-toggle';
import WoodHome from '../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import TopBunerProduct from '../components/top-buner/top-baner-product';
import OfficeAtMap from '../components/office-on-map/office-on-map';
import ImagesView from '../components/images-view/images-view';
import Advantages from '../components/advantages/advantages';
import GalleryInstagram from '../components/gallery-instagram/gallery-instagram';
import FarmPetersdorf from '../images/petersdorf-farm.jpg';


const About = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'О нас', url:'#', status:true}]
  return (
    <div>
        <SEO title="О Племенном центре Петерсдорф"
            description="">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Немного о нас'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
          
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-center">
                <h1>Племенной центр "Петерсдорф"</h1>
                <p className="text-justify">
                  Племенной центр "Petersdorf" занимается продажей сельскохозяйственных животных элитных пород коз и овец в России.
                  Мы работаем только с лучшими Европейскими животноводческими фермами, которые поставляют нам породистых,
                  чистых животных разных пород с отличными родословными.
                  У нас вы можете заказать коз и овец любых пород и мы доставим их в Россию вкратчайше сроки. 
                  При покупке коз и овец из Европы и поставке их в РФ, Вам не нужно получать разрешение на ввоз животных 
                  в Минсельхозе и Россельхознадзоре, племенной центр берет оформление всех документов на себя. 
                  Вы только приезжаете на наши карантийные фермы и забираете своих любимцев. 
                  Европейские ветеринарные сертификаты и обследование на карантине российскими 
                  ветврачами гарантируют чистоту животных.
                </p>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={FarmPetersdorf} className="w-100" alt="Племенной центр Петерсдорф" title="Племенной центр Петерсдорф" />
              </Col>
            </Row>
        </Container>
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default About;